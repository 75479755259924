<template>
  <Modal :display="display" @close="$emit('close')">
    <h4 class="text-dark font-semibold text-xl">Product details</h4>
    <div class="mt-6">
      <p class="text-sm">Merchant</p>
      <p class="text-grey font-semibold">{{ paymentDetails.merchant.name }}</p>
    </div>
    <div class="mt-4">
      <p class="text-sm">Order ID</p>
      <p class="text-grey font-semibold">#{{ paymentDetails.orderCode }}</p>
    </div>
    <div class="mt-4">
      <p class="text-sm">Title of purchase</p>
      <p class="text-grey font-semibold">{{ paymentDetails.orderTitle }}</p>
    </div>
    <div class="mt-4">
      <p class="text-sm">Date & time</p>
      <p class="text-grey font-semibold">{{ dayjs(paymentDetails.createdAt).format("MMMM DD YYYY") }}</p>
    </div>
    <div class="mt-4">
      <p class="text-sm">Repayment schedule</p>
      <p class="text-grey font-semibold">{{ handlePaymentPlan(paymentDetails.paymentPlan) }}</p>
    </div>
    <div class="mt-4">
      <p class="text-sm">Amount</p>
      <p class="text-grey font-semibold">{{ paymentDetails.amountOfOrder | formatMoney }}</p>
    </div>
    <div class="button mt-10">
      <Button width="w-full" text="Download reciept" @click="handleDownloadReciept" />
      <p class="text-sm font-semibold cursor-pointer text-brandPurple text-center mt-4" @click="handleShareReciept" v-if="showShare">Share reciept</p>
    </div>
  </Modal>
</template>
<script>
  import { mapActions } from "vuex";
  import { Modal } from "@/UI/Modals";
  import { Button } from "@/UI/Button";
  import dayjs from "dayjs";
  import { GET_PURCHASE_RECIEPT } from "@/utils/api/purchase";

  export default {
    components: {
      Modal,
      Button,
    },
    props: {
      display: {
        type: Boolean,
        default: false,
        reuired: true,
      },
      paymentDetails: {
        type: Object,
        default: () => {},
        required: true,
      },
      recieptProp: {},
    },
    data: () => ({
      recieptBlob: null,
    }),
    computed: {
      dayjs() {
        return dayjs;
      },
      showShare() {
        return !!navigator.share;
      },
      activeRecieptBlob() {
        return this.recieptBlob || this.recieptProp;
      },
    },
    mounted() {
      if (!this.recieptProp) {
        this.getReciept();
      }
    },
    methods: {
      ...mapActions("loading", ["setLoading"]),
      getReciept() {
        this.setLoading({
          display: true,
          description: "Generating your reciept",
        });
        GET_PURCHASE_RECIEPT(this.paymentDetails.id)
          .then(({ data }) => {
            this.recieptBlob = data;
            this.setLoading(false);
          })
          .catch(() => {
            this.setLoading(false);
          });
      },
      handleDownloadReciept() {
        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(this.activeRecieptBlob);
        anchor.setAttribute("download", "Zilla purchase Reciept");
        document.body.appendChild(anchor);
        anchor.click();
        // URL.revokeObjectURL(this.recieptBlob)
      },
      handleShareReciept() {
        const file = new File([this.activeRecieptBlob], "reciept.png", { type: this.activeRecieptBlob.type });
        const shareData = {
          title: "Zilla purchase reciept",
          files: [file],
        };
        if (navigator.share) {
          navigator
            .share(shareData)
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        }
      },
      handlePaymentPlan(type) {
        let plan;
        switch (type) {
          case "INSTALLMENT":
            plan = "4 payments";
            break;
          case "STRETCH":
            plan = "3 payments";
            break;
          case "SPLIT":
            plan = "2 payments";
            break;
          case "INSTANT":
            plan = "paid in full";
        }
        return plan;
      },
    },
  };
</script>
