var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { display: _vm.display },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("h4", { staticClass: "text-dark font-semibold text-xl" }, [
        _vm._v("Product details"),
      ]),
      _c("div", { staticClass: "mt-6" }, [
        _c("p", { staticClass: "text-sm" }, [_vm._v("Merchant")]),
        _c("p", { staticClass: "text-grey font-semibold" }, [
          _vm._v(_vm._s(_vm.paymentDetails.merchant.name)),
        ]),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("p", { staticClass: "text-sm" }, [_vm._v("Order ID")]),
        _c("p", { staticClass: "text-grey font-semibold" }, [
          _vm._v("#" + _vm._s(_vm.paymentDetails.orderCode)),
        ]),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("p", { staticClass: "text-sm" }, [_vm._v("Title of purchase")]),
        _c("p", { staticClass: "text-grey font-semibold" }, [
          _vm._v(_vm._s(_vm.paymentDetails.orderTitle)),
        ]),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("p", { staticClass: "text-sm" }, [_vm._v("Date & time")]),
        _c("p", { staticClass: "text-grey font-semibold" }, [
          _vm._v(
            _vm._s(
              _vm.dayjs(_vm.paymentDetails.createdAt).format("MMMM DD YYYY")
            )
          ),
        ]),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("p", { staticClass: "text-sm" }, [_vm._v("Repayment schedule")]),
        _c("p", { staticClass: "text-grey font-semibold" }, [
          _vm._v(_vm._s(_vm.handlePaymentPlan(_vm.paymentDetails.paymentPlan))),
        ]),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("p", { staticClass: "text-sm" }, [_vm._v("Amount")]),
        _c("p", { staticClass: "text-grey font-semibold" }, [
          _vm._v(
            _vm._s(_vm._f("formatMoney")(_vm.paymentDetails.amountOfOrder))
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "button mt-10" },
        [
          _c("Button", {
            attrs: { width: "w-full", text: "Download reciept" },
            on: { click: _vm.handleDownloadReciept },
          }),
          _vm.showShare
            ? _c(
                "p",
                {
                  staticClass:
                    "text-sm font-semibold cursor-pointer text-brandPurple text-center mt-4",
                  on: { click: _vm.handleShareReciept },
                },
                [_vm._v("Share reciept")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }